<template>
  <div class="home-page">
    <Nav />
    <div class="home-wrap">
      <van-swipe class="top-swipe" :autoplay="3000" indicator-color="#FC0">
        <van-swipe-item v-for="(item, index) in topList" :key="index">
          <img :src="item.imgUrl" />
          <div class="title-box">
            <h1>{{ item.firstTitle }}<br />{{ item.secondTitle }}</h1>
            <span>了解更多信息</span>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="news-list">
        <div class="news-title">最新新闻</div>
        <div class="news-card">
          <div v-for="item in newsList" :key="item.id" class="news" @click="goDetail(item.uuid)">
            <img :src="item.cover_image" alt="" />
            <div class="about">
              <div class="about-top">
                <span class="tag">{{ item.tags[0] }}</span>
                <span class="date">{{ formatDate(item.create_time) }}</span>
              </div>
              <div class="brief">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-list">
        <div class="tab-wrap">
          <div class="btn-circle">
            <div
              v-for="name in tabList"
              :key="name"
              class="normal-btn"
              @click="toggleTab(name)"
              :class="{ active: tabActive === name }"
            >
              {{ name }}
            </div>
          </div>
        </div>
        <van-swipe
          v-if="tabActive === '最新视频'"
          class="video-swipe info-swipe"
          indicator-color="#FC0"
        >
          <van-swipe-item v-for="(item, index) in videoList" :key="index">
            <div class="circle-box" @click="goVideoDetail(item.uuid)">
              <img :src="item.cover_image"/>
              <div class="title-box">
                {{ item.name }}
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <van-swipe
          v-if="tabActive === '最新会议'"
          class="meeting-swipe info-swipe"
          indicator-color="#FC0"
        >
          <van-swipe-item v-for="(item, index) in meetingList" :key="index">
            <div class="circle-box" @click="goMeetingDetail(item.uuid)">
              <img :src="item.cover_image" />
              <div class="text-box">
                <div class="about-top">
                  <div class="tag">{{ item.tags[0] }}</div>
                  <div class="date">{{ formatDate(item.create_time) }}</div>
                </div>
                <div class="title">{{ item.name }}</div>
                <div class="brief" v-html="item.detail"></div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="telegraph">
        <div class="news-box">
          <img class="img01" src="../../image/telegraph.png" alt="" />
          <img class="img02" src="../../image/telegraph.png" alt="" />
        </div>
        <div class="btn-box">
          <div class="subscribe" @click="openIframeForm">订阅更多</div>
        </div>
      </div>
      <div class="home-footer">
        <Footer class="custom-footer" />
      </div>
      <Customer />
    </div>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import Footer from "../../component/footer.vue";
import Customer from "../../component/onlineCustomer.vue";
import { Swipe, SwipeItem } from "vant";
import request from '../../utils/request';
import dayjs from 'dayjs';
export default {
  name: "HOME",
  components: {
    Nav,
    Footer,
    Customer,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      tabActive: "最新视频",
      topList: [
        {
          imgUrl: require("../../image/index-bg.png"),
          firstTitle: "新一代模块化",
          secondTitle: "工业连接器",
          link: "xxxxxxxxx",
        },
        {
          imgUrl: require("../../image/index-bg.png"),
          firstTitle: "新一代模块化02",
          secondTitle: "工业连接器02",
          link: "xxxxxxxxx",
        },
        {
          imgUrl: require("../../image/index-bg.png"),
          firstTitle: "新一代模块化02",
          secondTitle: "工业连接器03",
          link: "xxxxxxxxx",
        },
      ],
      newsList: [],
      tabList: ["最新视频", "最新会议"],
      videoList: [],
      meetingList: [],
    };
  },
  methods: {
    toggleTab(name) {
      this.tabActive = name;
    },
    formatDate(date) {
      return dayjs(date).format('YYYY.MM.DD');
    },
    fetchNewsList() {
      request.get('/news/list',{
        page_size: 4,
        page_num: 1
      }) // 假设接口地址为 /news
        .then(response => {
          this.newsList = response.data; // 将接口返回的数据赋值给newsList
        })
        .catch(error => {
          console.error('Error fetching news list:', error);
        });
    },
    fetchVideosList() {
      request.get('/video/list',{
        page_size: 4,
        page_num: 1
      }) // 假设接口地址为 /news
        .then(response => {
          this.videoList = response.data; // 将接口返回的数据赋值给newsList
        })
        .catch(error => {
          console.error('Error fetching news list:', error);
        });
    },
    fetchMeetingsList() {
      request.get('/meeting/list',{
        page_size: 4,
        page_num: 1
      }) // 假设接口地址为 /news
        .then(response => {
          this.meetingList = response.data; // 将接口返回的数据赋值给newsList
        })
        .catch(error => {
          console.error('Error fetching news list:', error);
        });
    },
    goDetail(newsId) {
      // 传递新闻ID到新闻详情页面
      this.$router.push({ name: 'newsDetail', query: { id: newsId } });
    },
    goVideoDetail(id) {
      // 传递新闻ID到新闻详情页面
      this.$router.push({ name: 'videosDetail', query: { id: id } });
    },
    goMeetingDetail(id) {
      // 传递新闻ID到新闻详情页面
      this.$router.push({ name: 'meetingDetail', query: { id: id } });
    },
    openIframeForm() {
      window.location.href = `https://www.harting.com.cn/CN/zh-hans/form/asia-general-contact-form`
    },
  },
  created() {
    this.fetchNewsList()
    this.fetchVideosList()
    this.fetchMeetingsList()
  }
};
</script>
<style lang="less" scoped>
.home-page {
  width: 100%;
  min-height: 100vh;
  background: #fdfefe;
}
.home-wrap {
  position: relative;
  .top-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 592px;
    background: url("../../image/index-bg.png") no-repeat center / contain;
  }
  .top-swipe {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 592px;
      object-fit: contain;
    }
    .title-box {
      position: absolute;
      top: 360px;
      left: 32px;
      h1 {
        margin-bottom: 10px;
        font-size: 40px;
        color: #212027;
        font-weight: 800;
        line-height: 48px;
      }
      span {
        display: inline-block;
        box-sizing: content-box;
        background: #ffcc00;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding: 0 24px;
        border-radius: 40px;
        color: #212027;
        font-weight: 600;
      }
    }
  }

  .news-list {
    box-sizing: border-box;
    position: relative;
    top: -40px;
    border-top: 2px solid #fff;
    border-radius: 40px 40px 0 0;
    width: 100%;
    // height: 669px;
    background: linear-gradient(180deg, #f0f5f7 0%, #ffffff 87.52%);
    .news-title {
      padding: 40px 32px 32px 32px;
      font-size: 40px;
      font-weight: 800;
      color: #212027;
    }
    .news-card {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 20px;
      width: calc(100% - 40px);
      .news {
        margin-bottom: 16px;
        border: 1px solid #fff;
        width: 160px;
        border-radius: 24px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
      }
      img {
        display: block;
        width: 160px;
        height: 160px;
        object-fit: cover;
      }
      .about {
        padding: 10px 10px 12px 10px;
      }
      .about-top {
        margin-bottom: 8px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .tag {
          padding: 0 4px;
          height: 16px;
          background: #ffcc00;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #212027;
          border-radius: 4px;
        }
        .date {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #a9a9ac;
        }
      }
      .brief {
        font-size: 16px;
        line-height: 22px;
        color: #212027;
        font-weight: 600;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .info-list {
    position: relative;
    top: -40px;
    width: 100%;
    height: 517px;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
    .tab-wrap {
      margin-bottom: 32px;
      padding-top: 40px;
      display: flex;
      justify-content: center;
      .btn-circle {
        display: flex;
        padding: 3px;
        height: 40px;
        background: #fff;
        border-radius: 80px;
        .normal-btn {
          padding: 8px 15px;
          line-height: 24px;
          font-size: 14px;
          text-align: center;
          font-weight: 300;
        }
        .active.normal-btn {
          background: #fc0;
          font-size: 16px;
          font-weight: 600;
          border-radius: 48px;
        }
      }
    }
    .info-swipe {
      .van-swipe__indicator {
        background: #d8e1e4;
      }
      .van-swipe-item {
        height: 300px;
        display: flex;
        justify-content: center;
      }
    }
    .video-swipe {
      .circle-box {
        width: 327px;
        height: 246px;
        border-radius: 24px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
      }
      img {
        display: block;
        width: 327px;
        height: 184px;
        object-fit: cover;
      }
      .title-box {
        padding: 16px 16px 24px 16px;
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .meeting-swipe {
      .circle-box {
        width: 327px;
        height: 260px;
        border-radius: 24px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
      }
      img {
        display: block;
        width: 327px;
        height: 137px;
        object-fit: cover;
      }
      .text-box {
        padding: 16px;
        line-height: 16px;
        font-size: 18px;
        font-weight: 600;
        width: 280px;
        .about-top {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          .tag {
            padding: 2px 4px;
            background: #ffcc00;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #212027;
            border-radius: 4px;
          }
          .date {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #a9a9ac;
          }
        }
        .title {
          margin-bottom: 8px;
          line-height: 22px;
          font-size: 18px;
          font-weight: 600;
        }
        .brief {
          font-size: 12px;
          line-height: 16px;
          color: #535359;
          font-weight: 400;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .telegraph {
    position: relative;
    box-sizing: border-box;
    padding: 57px 30px 0 30px;
    top: -97px;
    width: 375px;
    height: 590px;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: #fc0;
    .news-box {
      margin-bottom: 26px;
      position: relative;
    }
    .img01 {
      display: block;
      width: 320px;
      height: 401.208px;
      transform: rotate(-6deg);
      opacity: 0.8;
      background: url(<path-to-image>), lightgray 50% / cover no-repeat;
      filter: blur(2px);
    }
    .img02 {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 315px;
      height: 400px;
      object-fit: contain;
    }
    .btn-box {
      display: flex;
      justify-content: center;
      .subscribe {
        box-sizing: border-box;
        display: flex;
        width: 160px;
        height: 40px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 40px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .home-footer {
    position: relative;
    width: 100%;
    height: 80px;
    background: #212027;
    .custom-footer {
      position: absolute;
      top: -110px;
      left: 0;
    }
  }
}
</style>
<style lang="less">
.home-page {
  .top-swipe {
    .van-swipe__indicators {
      bottom: 80px;
      left: 300px;
      .van-swipe__indicator {
        background: #fff;
        opacity: 1;
      }
    }
  }

  .van-swipe__indicator {
    background: #d8e1e4;
  }
}
</style>
